<template>
  <Toast />
  <div class="p-grid p-d-flex p-flex-column p-jc-center p-ai-center">
    <div class="text-title p-fluid p-text-center" style="margin: 10px 0px 20px 0px;">
      <h2 class="p-mb-0" style="color:#424242; font-weight: 500;">Registro</h2>
    </div>
    <div class="card p-shadow p-radius" style="max-width:650px; width:100%;">
      <div class="card p-m-0">
        <!-- STEEPER -->
        <div class="p-d-flex p-jc-between p-ai-center p-mb-2">
          <div class="p-d-flex p-flex-column p-ai-center">
            <div>
              <Button icon="pi pi-user-edit" v-tooltip.bottom="'Datos generales'"
                class="p-button-rounded p-button-outlined" />
            </div>
          </div>
          <div class="progress" style="flex-grow:3;">
            <div :class="{ 'full-width': !is_first_step }" class="progress-bar"></div>
          </div>
          <div class="p-d-flex p-flex-column p-ai-center">
            <div>
              <Button icon="pi pi-map-marker" v-bind:class="{ 'p-button-secondary': is_first_step }"
                v-tooltip.bottom="'Datos de ubicación'" class="p-button-rounded p-button-outlined" />
            </div>
          </div>
          <div class="progress" style="flex-grow:3;">
            <div :class="{ 'full-width': is_third_step }" class="progress-bar"></div>
          </div>
          <div class="p-d-flex p-flex-column p-ai-center">
            <div>
              <Button icon="pi pi-key" v-bind:class="{ 'p-button-secondary': !is_third_step }"
                v-tooltip.bottom="'Datos de acceso'" class="p-button-rounded p-button-outlined" />
            </div>
          </div>
        </div>

        <div class="p-fluid p-mb-3">
          <small class="text-muted">Los campos marcados con * son obligatorios.</small>
        </div>

        <div class="p-fluid" v-bind:class="{ 'p-hidden': !is_first_step }">
          <StepOne @actualizarForm="actualizarFormulario" @changeStep="updateStep" />
        </div>

        <div class="p-fluid" v-bind:class="{ 'p-hidden': !is_seccond_step }">
          <StepTwo @actualizarForm="actualizarFormulario" @changeStep="updateStep" />
        </div>

        <div class="p-fluid" v-bind:class="{ 'p-hidden': !is_third_step }">
          <StepThree @set_info_adicional="set_info_adicional" @on_save_register="guardar_registro"
            :loading="guardar_is_loading" @changeStep="updateStep" />
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
  .progress {
    height: 2px !important;
    background: #d7d7d7;
  }

  .progress-bar {
    height: 2px !important;
    width: 0%;
    background: #007bff;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
  }

  .full-width {
    width: 100% !important;
  }

  .p-inputswitch-slider {
    border-radius: 50px !important;
  }
</style>
<script>

import StepOne from '@/pages/Register/components/StepOne.vue';
import StepTwo from '@/pages/Register/components/StepTwo.vue';
import StepThree from '@/pages/Register/components/StepThree.vue';

export default {
  components: {
    StepOne,
    StepTwo,
    StepThree
  },
  data() {
    return {
      p: [],
      isvalid: {
        nombres: true,
        apellidos: true,
        direccion: true,
        departamento: true,
        municipio: true,
        estado_civil: true,
        nacimiento: true,
        genero: true,
        correo: true,
        clave: true,
        clave2: true,
      },
      form: {
        tipoRegistro: 'e',
        nombres: '',
        apellidos: '',
        nacimiento: '',
        direccion: '',
        departamento: 0,
        municipio: 0,
        tipo_documento: 0,
        documento: '',
        correo: '',
        codpais: '',
        telefono: '',
        estado_civil: '',
        genero: '',
        clave: '',
        clave2: '',
        es_recla: false,
        es_extranjero: false,
        universidad_recla: 0,
        otra_universidad: '',
        universidad: ''
      },
      info_adicional: false,
      is_first_step: true,
      is_seccond_step: false,
      is_third_step: false,
      guardar_is_loading: false,
    }
  },
  methods: {
    updateStep(step) {
      if (step == 1) this.step_one();
      if (step == 2) this.step_two();
      if (step == 3) this.step_three();
    },
    set_info_adicional(d) {
      this.info_adicional = d;
    },
    actualizarFormulario(datos) {
      this.form = { ...this.form, ...datos };
    },
    async guardar_registro(datos) {
      this.actualizarFormulario(datos);

      console.log({ ...this.form });

      this.p = {
        tipo_cliente: this.form.tipoRegistro,
        nombre: this.form.nombres,
        apellido: this.form.apellidos,
        es_extranjero: this.form.es_extranjero,
        direccion: this.form.direccion,
        departamento: this.form.departamento,
        municipio: this.form.municipio,
        tipo_documento: this.form.tipo_documento,
        documento: this.form.documento,
        correo: this.form.correo,
        clave: this.form.clave,
        codpais: this.form.codpais,
        telefono: this.form.telefono,
        nacimiento: this.form.nacimiento,
        estado_civil: this.form.estado_civil,
        genero: this.form.genero,
        adicional: (this.info_adicional && this.form.es_recla),
        info: {
          descripcion: '',
          codigo: 0,
        }
      }

      this.guardar_is_loading = true
      this.correo_repetido = true;
      await this.axios.post("/Registro/AgregarCliente", this.p)
        .then(r => {
          console.log(r);
          this.$toast.add({
            severity: (r.data.errores ? 'error' : 'success'),
            summary: 'Mensaje',
            detail: r.data.mensaje,
            life: 2000
          })
          if (r.data == 2) {
            this.correo_repetido = false;
            return false;
          }
          if (!r.data.errores) {
            this.$store.commit('setRegistered', '1');
            this.$router.push("/");
          }
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error inesperado',
            detail: 'Algo ha salido mal, por favor vuelva a intentarlo',
            life: 2000
          })
        })
        .then(() => { this.guardar_is_loading = false })

    },
    step_one() {
      this.is_first_step = true;
      this.is_seccond_step = false;
      this.is_third_step = false;
    },
    step_two() {
      this.is_first_step = false;
      this.is_seccond_step = true;
      this.is_third_step = false;
      return true;
    },
    step_three() {
      this.is_first_step = false;
      this.is_seccond_step = false;
      this.is_third_step = true;
      return true;
    },
  },
  computed: {

  },
  mounted() {

  }
}
</script>