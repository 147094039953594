<template>

  <div class="p-d-flex p-ai-center p-flex-column p-mb-2">
    <div class="p-pb-2">
      <span>TIPO DE REGISTRO SEGÚN SU ORIGEN</span>
    </div>
    <div class="p-grid p-d-flex p-jc-center p-ai-center p-p-2">
      <div class="flex align-items-center p-pr-4">
        <RadioButton v-model="formulario.tipoRegistro"  id="pizza3" name="pizza3" value="x" />
        &nbsp;<label for="pizza3" htmlFor="pizza3" class="ml-2">Externo</label>
      </div>
      <div class="flex align-items-center p-pr-4" v-tooltip.bottom="'Se validará mediante documento'" >
        <RadioButton v-model="formulario.tipoRegistro" id="pizza" value="e" />
        &nbsp;<label for="pizza" htmlFor="pizza" class="ml-2">Estudiante UGB</label>
      </div>
      <div class="flex align-items-center" v-tooltip.bottom="'Se validará mediante documento'">
        <RadioButton v-model="formulario.tipoRegistro" id="pizza2" name="pizza2" value="p" />
        &nbsp;<label for="pizza2" htmlFor="pizza2"  class="ml-2">Personal UGB</label>
      </div>
    </div>
  </div>

  <!-- {{ formato }} -->
  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="dui">*Documento:</label>
    </div>
    <div class="p-col-9 ">
      <div class="p-dol-12 p-w-0 p-m-0 p-d-flex">
        <Dropdown 
          v-model="formulario.tipo_documento" 
          id="tipo_documento" 
          :loading="tipo_doc_isloading" 
          :options="tipo_documento_list" 
          optionLabel="nombre" 
          optionValue="codigo" 
          placeholder="Seleccione" />
        <InputText 
          id="documento" 
          v-bind:class="{'p-invalid' : !isvalid.documento}" 
          v-model="formulario.documento" 
          autocomplete="off" 
          placeholder="Escriba el documento" />
      </div>
      <small class="text-danger" v-if="!isvalid.documento">Ingrese un documento válido de acuerdo al tipo seleccionado</small>
    </div>
  </div>

  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="nombres">*Nombres:</label>
    </div>
    <div class="p-col-9">
      <InputText 
        id="nombres" 
        v-bind:class="{'p-invalid' : !isvalid.nombres}" 
        v-model="formulario.nombres" 
        autocomplete="off" 
        placeholder="Escriba sus nombres" />
    </div>
  </div>

  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="apellidos">*Apellidos:</label>
    </div>
    <div class="p-col-9">
      <InputText 
        id="apellidos" 
        v-bind:class="{'p-invalid' : !isvalid.apellidos}" 
        v-model="formulario.apellidos" 
        autocomplete="off" 
        placeholder="Escriba sus apellidos" />
    </div>
  </div>

  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label>*Nacimiento:</label>
    </div>
    <div class="p-col-9">
      <InputMask 
        mask="*9/*9/9999" 
        id="nacimiento" 
        v-model="formulario.nacimiento" 
        v-bind:class="{'p-invalid' : !isvalid.nacimiento}" 
        autocomplete="off" 
        slotChar="dd/mm/yyyy"
        placeholder="dd/mm/yyyyy"/>
    </div>
  </div>

  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="estado_civil">*Estado civil:</label>
    </div>
    <div class="p-col-9">
      <Dropdown 
        v-model="formulario.estado_civil" 
        id="estado_civil" 
        v-bind:class="{'p-invalid' : !isvalid.estado_civil}" 
        :loading="estado_civil_is_loading" 
        :options="estado_civil_list" 
        optionLabel="nombre" 
        optionValue="codigo" 
        placeholder="Seleccione" />
    </div>
  </div>

  <div class="p-mt-1 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="">*Género:</label>
    </div>
    <div class="p-col-9">
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox p-mb-0">
          <RadioButton 
            id="f" 
            name="genero" 
            value="f" 
            v-model="formulario.genero" />
          <label for="f">Femenino</label>
        </div>
        <div class="p-field-checkbox p-mb-0">
          <RadioButton 
            id="m" 
            name="genero" 
            value="m" 
            v-model="formulario.genero" />
          <label for="m">Másculino</label>
        </div>
      </div>
      <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid.genero }">Debe seleccionar su género.</small>
    </div>
  </div>

  <div class="p-col p-p-0 p-d-flex p-jc-between p-ai-center p-mt-1">
    <router-link to="/">
      <Button label="Ir a iniciar sesión" class="p-button-text"/>
    </router-link>
    <div>
      <Button label="Siguiente" class="p-button-primary" @click="step_two" icon="pi pi-angle-double-right" iconPos="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepOne',
  props : {
    
  },
  data(){
    return {
      estado_civil_list: [],
      estado_civil_is_loading : true,
      tipo_documento_list : [],
      tipo_doc_isloading : true,
      formulario : {
        tipoRegistro : 'x',
        tipo_documento : 1,
				documento : '',
				nombres : '',
				apellidos : '',
        nacimiento : '',
				estado_civil : '',
				genero : '',
      },
      isvalid : {
        documento : true,
        nombres : true,
        apellidos : true,
        estado_civil : true,
        nacimiento : true,
        genero : true,
      },
    }
  },
  methods : {
    step_two(){
      const regex = new RegExp(this.formato);
      this.isvalid.documento = this.formulario.documento.length == 0 || !regex.test(this.formulario.documento) ? false : true;
      this.isvalid.nombres = this.formulario.nombres.length == 0 ? false : true;
      this.isvalid.apellidos = this.formulario.apellidos.length == 0 ? false : true;
      this.isvalid.estado_civil = this.formulario.estado_civil.length == 0 ? false : true;
      this.isvalid.nacimiento = this.formulario.nacimiento == 0 || !this.validar_fecha(this.formulario.nacimiento) ? false : true;
      this.isvalid.genero = this.formulario.genero.length == 0 ? false : true;

      let v = Object.values(this.isvalid).indexOf(false) >= 0;
      console.log(v);
			if(v) return false;

      this.$emit('actualizarForm', this.formulario);
      this.$emit('changeStep', 2);
      
    },
    actualizarForm(){
      this.$emit('actualizarForm', this.formulario);
    },
    validar_fecha(fecha){
      var partesFecha = fecha.split('/');
      var dia = parseInt(partesFecha[0]);
      var mes = parseInt(partesFecha[1]) - 1;
      var anio = parseInt(partesFecha[2]);

      var e = new Date(anio, mes, dia);

      return e.getDate() === dia && e.getMonth() === mes && e.getFullYear() === anio;
    },
    async obtener_tipoDocumentos(){
			this.tipo_doc_isloading = true
			await this.axios.get("/Registro/TipoDocumentos")
        .then(r => { this.tipo_documento_list = r.data })
        .catch(() => { })
        .then(() => { this.tipo_doc_isloading = false })
		},
    async obtener_estadosCiviles(){
			this.estado_civil_is_loading = true
			await this.axios.get("/Registro/EstadosCiviles")
        .then(r => { this.estado_civil_list = r.data })
        .catch(() => { })
        .then(() => { this.estado_civil_is_loading = false })
		}
  },
  watch : {

  },
  computed : {
      formato : function(){
        return this.tipo_documento_list.length > 0 && this.tipo_documento_list.filter( x => x.codigo == this.formulario.tipo_documento )[0].formato;
      }
  },
  created : function(){
      this.obtener_estadosCiviles();
      this.obtener_tipoDocumentos();
  },
}
</script>