<template>


  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3 px-0">
      <label for="es_extranjero">Es extranjero:</label>
    </div>
    <div class="p-col-9">
      <InputSwitch 
        v-model="formulario.es_extranjero" />
    </div>
  </div>

  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label>*Contacto:</label>
    </div>
    <div class="p-col-9">
      <div class="p-col-12 p-w-0 p-p-0 p-m-0 p-d-flex">
        <InputText 
          id="codigo_pais" 
          style="width: 80px;"
          v-model="formulario.codpais"
          v-bind:class="{ 'p-invalid' : !isvalid.codpais }" 
          maxlength="4"
          placeholder="Ej. +503"/>
        <InputText 
          id="telefono" 
          v-model="formulario.telefono" 
          v-bind:class="{'p-invalid' : !isvalid.telefono}" 
          autocomplete="off" 
          placeholder="Su número de teléfono" />
      </div>
      <small class="text-danger" v-if="!isvalid.codpais">Ingrese el código Ej. +123</small>
      <br v-if="!isvalid.codpais && !isvalid.telefono">
      <small class="text-danger" v-if="!isvalid.telefono">Ingrese el número sin guiones</small>
    </div>
  </div>
  
  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="direccion">*Dirección:</label>
    </div>
    <div class="p-col-9">
      <InputText 
        id="direccion" 
        v-bind:class="{ 'p-invalid' : !isvalid.direccion }" 
        v-model="formulario.direccion" 
        autocomplete="off" 
        placeholder="Dirección de residencia" />
    </div>
  </div>

  
  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="departamento"><i v-if="!formulario.es_extranjero">*</i>Departamento:</label>
    </div>
    <div class="p-col-9">
      <Dropdown 
        v-bind:class="{ 'p-invalid' : !isvalid.departamento }" 
        placeholder="Seleccione" 
        v-model="formulario.departamento" 
        id="departamento" 
        @change="obtener_municipios" 
        :options="lista_departamentos" 
        optionLabel="departamento" 
        optionValue="codigo" 
        :loading="departamento_is_loading"/>
    </div>
  </div>
  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="municipio"><i v-if="!formulario.es_extranjero">*</i>Municipio:</label>
    </div>
    <div class="p-col-9">
      <Dropdown 
        v-bind:class="{ 'p-invalid' : !isvalid.municipio }" 
        placeholder="Seleccione" 
        v-model="formulario.municipio" 
        id="municipio" 
        :options="lista_municipios" 
        optionLabel="municipio" 
        optionValue="codigo" 
        :loading="municipio_is_loading" 
        emptyMessage="Seleccione departamento"/>
    </div>
  </div>
  
  <div class="p-col p-p-0 p-d-flex p-jc-between p-ai-center p-mt-1">
    <div><Button label="Volver" @click="step_one" class="p-button-text" icon="pi pi-angle-double-left" iconPos="left" /></div>
    <div><Button label="Siguiente" class="p-button-primary" @click="step_three" icon="pi pi-angle-double-right" iconPos="right" /></div>
  </div>
</template>

<script>
export default {
  name: 'StepTwo',
  props : {
    
  },
  data(){
    return {
      lista_departamentos: [],
      lista_municipios: [],
      departamento_is_loading : true,
      municipio_is_loading : false,
      formulario : {
        es_extranjero : false,
        codpais : '+503',
        telefono : '',
        direccion : '',
        departamento : 0,
        municipio : 0,
      },
      isvalid : {
        codpais : true,
        telefono : true,
        direccion : true,
        departamento : true,
        municipio : true,
      },
    }

  },
  methods : {
    step_one(){
      this.$emit('changeStep', 1);
    },
    step_three(){
      /*
        valida que el codigo de pais tenga un + y seguido de un codigo de 1 a 3 numeros, por defecto es +503
        valida que el numero de telefono tenga entre 8 y 11digitos para tomar en cuenta cualquier nummero de cualquier pais
      */
      this.isvalid.direccion = this.formulario.direccion.length == 0 ? false : true
      this.isvalid.codpais = this.formulario.codpais.length == 0 || !/^\+\d{1,3}$/.test(this.formulario.codpais) ? false : true
      this.isvalid.telefono = this.formulario.telefono.length == 0 || !/^\d{8,11}$/.test(this.formulario.telefono) ? false : true;
      this.isvalid.departamento = this.formulario.departamento == 0 && !this.formulario.es_extranjero ? false : true
      this.isvalid.municipio = this.formulario.municipio == 0 && !this.formulario.es_extranjero ? false : true

      let v = Object.values(this.isvalid).indexOf(false) >= 0;
			if(v) return false;

      this.$emit('actualizarForm', this.formulario);
      this.$emit('changeStep', 3);
    },
    async obtener_departamentos(){
			this.departamento_is_loading = true
			await this.axios.get("/Registro")
            .then(r => { this.lista_departamentos = r.data })
            .catch(() => { })
			.then(() => { this.departamento_is_loading = false })
		},
		async obtener_municipios(){
			this.municipio_is_loading = true
			await this.axios.get("/Registro/Municipios/"+this.formulario.departamento)
				.then(r => { this.lista_municipios = r.data })
				.catch(() => { })
				.then(() => { this.municipio_is_loading = false })
		},
  },
  watch : {

  },
  computed : {
      
  },
  created : function(){
      this.obtener_departamentos();
      //this.obtener_municipios();
  },
}
</script>