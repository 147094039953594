<template>
  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="nombres">*Correo:</label>
    </div>
    <div class="p-col-9 p-d-flex p-flex-column">
      <InputText 
        id="correo" 
        v-bind:class="{ 'p-invalid' : !email_is_valid || !correo_repetido || !isvalid.correo}" 
        @keydown="isvalid.correo = true; correo_repetido = true;" 
        autocomplete="off"
        v-model="formulario.correo" 
        placeholder="Escriba su dirección de correo" />
      <small class="text-danger" v-bind:class="{ 'p-hidden' : email_is_valid }">Debe ingresar un correo válido.</small>
      <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid.correo }">Debe ingresar el correo.</small>
      <small class="text-danger" v-bind:class="{ 'p-hidden' : correo_repetido }">Este correo ya se encuentra registrado.</small>
    </div>
  </div>

  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="nombres">*Contraseña:</label>
    </div>
    <div class="p-col-9 p-d-flex p-flex-column">
      <Password 
        v-model="formulario.clave" 
        v-bind:class="{ 'p-invalid' : !isvalid.clave || !claves_coinciden}" 
        @keydown="isvalid.clave = true;" 
        weakLabel="Debil" 
        mediumLabel="Media" 
        strongLabel="Fuerte" 
        toggleMask  
        placeholder="Ingrese la contraseña">
        <template #footer="sp">
          {{sp.level}}
          <Divider />
          <span>Sugerencias:</span>
          <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
            <li>Al menos una mayuscula</li>
            <li>Al menos una minuscula</li>
            <li>Al menos un número</li>
            <li>Entre 8 y 16 caracteres</li>
          </ul>
        </template>
      </Password>
      <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid.clave }">Debe ingresar la contraseña.</small>
    </div>
  </div>

  <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
    <div class="p-col-3">
      <label for="nombres">*Confirmar:</label>
    </div>
    <div class="p-col-9 p-d-flex p-flex-column">
      <Password 
        v-model="formulario.clave2" 
        v-bind:class="{ 'p-invalid' : !isvalid.clave2 || !claves_coinciden}" 
        @keydown="isvalid.clave2 = true;" 
        :feedback="false" 
        id="clave2" 
        placeholder="Ingrese nuevamente la contraseña"></Password>
      <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid.clave2 }">Debe confirmar la contraseña.</small>
      <small class="text-danger" v-bind:class="{ 'p-hidden' : claves_coinciden }">Las contraseñas no coinciden.</small>
    </div>
  </div>
  
  <fieldset v-if="info_adicional" style="border:1px solid #CED4DA !important;">
    <legend>Evento Recla</legend>
    <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
      <div class="p-col-3 px-0">
        <label for="es_recla">Asistirá a evento Recla:</label>
      </div>
      <div class="p-col-9">
        <InputSwitch v-model="formulario.es_recla" />
      </div>
    </div>
    <div v-bind:class="{'p-hidden' : !formulario.es_recla}" >
      <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
        <div class="p-col-3 px-0">
          <label for="es_recla">*Seleccione universidad:</label>
        </div>
        <div class="p-col-9">
          <Dropdown 
            placeholder="Seleccione" 
            v-model="formulario.universidad_recla"
            id="universidad" 
            :options="lista_universidades" 
            optionLabel="nombre" 
            optionValue="codigo" />
        </div>
      </div>
      <div v-if="(formulario.universidad_recla == 0)">
        <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
          <div class="p-col-3">
            <label for="nombres">*Nombre de universidad:</label>
          </div>
          <div class="p-col-9 p-d-flex p-flex-column">
            <InputText id="correo" v-model="formulario.otra_universidad" placeholder="Nombre de universidad" />
          </div>
        </div>
      </div>
    </div>
  </fieldset>
  <div class="p-col p-p-0 p-d-flex p-jc-between p-ai-center p-mt-5">
    <div>
      <Button label="Volver" @click="step_two" class="p-button-text" icon="pi pi-angle-double-left" iconPos="left" />
    </div>
    <div>
      <Button label="Guardar registro" @click="guardar_registro" :loading="loading" icon="pi pi-save" />	
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepThree',
  props : {
    loading: Boolean
  },
  data(){
    return {
      info_adicional : false,
      guardar_is_loading : false,
      correo_repetido : true,
      formulario : {
        correo : '',
        clave : '',
        clave2 : '',
        es_recla : false,
        universidad_recla : 0,
        otra_universidad : ''
      },
      isvalid : {
        correo : true,
        clave : true,
        clave2 : true,
        universidad_recla : true,
        otra_universidad : true
      },
    }
  },
  methods : {
    guardar_registro(){
      this.isvalid.correo = this.formulario.correo.length == 0 ? false : true;
      this.isvalid.clave = this.formulario.clave.length == 0 ? false : true;
      this.isvalid.clave2 = this.formulario.clave2.length == 0 ? false : true;

      if(!this.email_is_valid) return false;
			if(!this.claves_coinciden) return false;

      if(this.info_adicional){
        if(this.formulario.es_recla){
          this.isvalid.universidad_recla = this.formulario.universidad_recla === 0 ? false : true;
          this.isvalid.otra_universidad = (
            this.formulario.universidad_recla !== 0 ? true : 
              this.formulario.universidad_recla === 0 && this.formulario.otra_universidad != 0 ? true : false
          );
        }
      }

      let v = Object.values(this.isvalid).indexOf(false) >= 0;
			if(v){
        return false;
      }else{
        this.$emit('on_save_register', { ...this.formulario, universidad: this.universidad });
      }
    },
    step_two(){
      this.$emit('changeStep', 2);
    },
    async requiere_info_adicional(){
			await this.axios.get("/Registro/InfoAdicional")
				.then(r => { 
          this.info_adicional = (r.data.length > 0);
          this.$emit('set_info_adicional', (r.data.length > 0));
         })
				.catch(() => { });
		},
    async obtener_universidades_recla(){
			await this.axios.get("/Registro/UniversidadesRecla")
				.then(r => { this.lista_universidades = r.data })
				.catch(() => { });
		},
  },
  watch : {

  },
  computed : {
    claves_coinciden : function(){ return !(this.formulario.clave != this.formulario.clave2) },
    email_is_valid : function(){
			//eslint-disable-next-line
			let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
			return this.formulario.correo == '' ? true : re.test(this.formulario.correo)
		},
    universidad : function (){
      return this.formulario.otra_universidad.length > 0 ? 
        this.formulario.otra_universidad : 
        this.lista_universidades.filter( x => x.codigo == this.formulario.universidad_recla )[0].nombre ;
    }
  },
  created : function(){
    this.obtener_universidades_recla();
    this.requiere_info_adicional();
  },
}
</script>